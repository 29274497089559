

































































































































































































































































































import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import {Machine} from "@/models/machine";
import {ChecklistElement, ElementCategory, ElementStatus} from "@/models/checklistElement";


@Component({
  components: {
    ChecklistRadio: () => import('./../components/radio/ChecklistRadio.component.vue'),
    ChecklistTableRow: () => import('./../components/ChecklistTableRow.component.vue'),
  }
})
export default class EditChecklistComponent extends Vue {

  data() {
    return {
      valid: false,
      required(propertyType: any) {
        return (v: any) => v && v.length > 0 || `You must input a ${propertyType}`;
      },
      maxLength(propertyType: any, maxLength: number) {
        return (v: any) => v && v.length <= maxLength || `${propertyType}  must have max  ${maxLength} characters`;
      },
      minLength(propertyType: any, min: number) {
        return (v: any) => v && v.length >= min || `${propertyType}  must have minimum  ${min} characters`;
      }
    }
  }

  @Prop({default: () => new Machine({})})
  public machine!: Machine;

  public opticList: ChecklistElement[] = [];
  public mechanicList: ChecklistElement[] = [];
  public electricList: ChecklistElement[] = [];
  public stueckList: string[] = ['1 Stk.', '2 Stk.', '3 Stk.', '4 Stk.', 'N.A.'];

  public mounted() {
    this.mechanicList = this.machine.checklist.filter((arg) => arg.category === ElementCategory.mechanic);
    this.electricList = this.machine.checklist.filter((arg) => arg.category === ElementCategory.electric);
    this.opticList = this.machine.checklist.filter((arg) => arg.category === ElementCategory.optic);
  }

  public removeVentil(index: any, item: ChecklistElement) {
    if (item.category === ElementCategory.mechanic) {
      this.mechanicList = this.mechanicList.filter(element => element !== item);
    } else if (item.category === ElementCategory.optic) {
      this.opticList = this.opticList.filter(element => element !== item);
    } else if (item.category === ElementCategory.electric) {
      this.electricList = this.electricList.filter(element => element !== item);
    }
  }

  public singleVentil(item: ChecklistElement) {
    return item.isCopiedElement;
  }

  public addVentil(index: any, item: any) {
    const copy = new ChecklistElement(item);
    copy.isCopiedElement = true;
    copy.elementStatus = ElementStatus.notSet;
    copy.comment = "";
    copy.serialNo = "";
    if (copy.category === ElementCategory.mechanic) {
      const index = this.mechanicList.indexOf(item);
      this.mechanicList.splice(index + 1, 0, copy);
    } else if (copy.category === ElementCategory.optic) {
      const index = this.opticList.indexOf(item);
      this.opticList.splice(index + 1, 0, copy);
    } else if (copy.category === ElementCategory.electric) {
      const index = this.electricList.indexOf(item);
      this.electricList.splice(index + 1, 0, copy);
    }
  }

  public cancel() {
    this.$emit('closeDialog', false);
  }

  public async saveChecklist(edited: boolean) {
    this.machine.checklist = [this.opticList, this.mechanicList, this.electricList].flat();
    // update
    this.$emit('closeDialog', edited);
  }

}

